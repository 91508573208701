<template>
    <b-card class="m-1" body-class="d-flex flex-column justify-content-center align-items-center">
        <b-img fluid width="300" :src="img" class="m-3"/>
        <h3 class="text-muted my-2 text-center">Kullanıcı onaylanıyor...</h3>
        <b-spinner variant="primary"></b-spinner>
    </b-card>
</template>

<script>
import { BCard,BSpinner,BImg } from 'bootstrap-vue'
import { UserApi } from '@/api/user'
export default {
    components:{
        BCard,
        BSpinner,
        BImg
    },
    mounted(){
        this.confirm()
    },  
    data() {
        return {
            code:this.$route.params?.code || null,
            img:require('@/assets/images/illustrations/undraw-security-on.svg')
        }
    },
    methods:{
        confirm(){
            if(this.code){
                UserApi.Confirm(this.code).then(res=>{
                    this.$result(res)
                    this.$router.push({ name: 'login' })
                }).catch(err=>{
                    this.$result(null,err)
                    this.$router.push({ name: 'login' })
                })
            }else{
                this.$router.push({ name: 'login' })
            }
        }
    }
}
</script>

<style>

</style>